import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "../style.css";
import {
  Form,
  Input,
  Button,
  DatePicker,
  Table,
  Radio,
  Spin,
  Pagination,
  Select,
  Space
} from "antd";
import API, { STATISTIC, BOOKMAKER, GAME } from "../../../network/api";
import moment from "moment";
import "moment-timezone";
import { TEXT_DEF } from "../../../constant/text";
import { Chart as ChartJS, ArcElement, Tooltip, Legend, BarElement } from 'chart.js';
import { getGame, getCategory, extractNumbers, getGameNameXSTT } from "../../../components/until";
import ModalChart from "../modal";
import { OP_CATEGORY, OP_GAME_ORIGINALS } from "../constant";
import { RIGHTS } from "../../../constant/rights";
import { hasRight } from "../../../components/until/right";
import dayjs from 'dayjs';
import { Option } from "antd/es/mentions";
import * as XLSX from 'xlsx';
import {saveAs} from 'file-saver';
import { disabledTimeTo, disabledTimeFrom, validateDateRange } from "../../../utils/dateUtils";

ChartJS.register(ArcElement, Tooltip, Legend, BarElement);

const ReportByGame = () => {
  const auth = JSON.parse(localStorage.getItem("auth"));
  const [form] = Form.useForm();
  const [, forceUpdate] = useState({});
  const navigate = useNavigate();
  const [dateFrom, setDateFrom] = useState();
  const [dateTo, setDateTo] = useState();
  const [indexTab, setIndexTab] = useState(1);
  localStorage.removeItem("indexTabAcc");
  const [loading, setLoading] = useState(false);
  const [typeSl, setTypeSl] = useState();
  const [typeChartSl, setTypeChartSl] = useState();
  const [opBookmaker, setOpBookmaker] = useState()
  const [bookmakerSl, setBookmakerSl] = useState(0);
  const [dataChart, setDataChart] = useState(undefined);
  const [dataChartModal, setDataChartModal] = useState(undefined);
  const [titleChartModal, setTitleChartModal] = useState(undefined);
  const [isShowModalChart, setIsShowModalChart] = useState(false);
  const [dataGame, setDataGame] = useState();

  const [opGameCategory, setOpGameCategory] = useState(OP_CATEGORY);
  const [gameCategory, setGameCategory] = useState();
  const [game, setGame] = useState();
  const [opGame, setOpGame] = useState();

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalRecord, setTotalRecord] = useState(0);
  const [dataList, setDataList] = useState([]);

  const [bookmarkers, setBookmarkers] = useState();
  const [bookmarkerSelected, setBookmarkerSelected] = useState(1);
  const [searchBy, setSearchBy] = useState();
  const [gameTypes, setGameTypes] = useState();
  const location = useLocation();
  const state = location.state;
  const [finalInfo, setFinalInfo] = useState({
    allOrders: 0,
    allBet: 0,
    allAmountUserWin: 0,
    allProfit: 0,
    allOrdersWin: 0,
    allPercentProfit: 0,
  });
  // const [finalInfoPP, setFinalInfoPP] = useState({
  //   allOrders: 0,
  //   allBet: 0,
  //   allAmountUserWin: 0,
  //   allProfit: 0,
  //   allOrdersWin: 0,
  //   allPercentProfit: 0,
  // });
  const locale = {
    emptyText: TEXT_DEF.text0043,
  };

  useEffect(() => {

    if(gameTypes && game) {
      form.setFieldsValue({ gameType: game});
    }
    if (bookmarkers && gameTypes) {
      checkState()
    }
   
  }, [bookmarkers, gameTypes, form])


  const checkState = async () => {
    if(state) {
      let objState = {}
      const {bookmaker, user} = state;
      if (bookmaker) {
        const bookmakerA = bookmarkers.find(item => item.label == bookmaker.bookmarkerName);
        setBookmarkerSelected(bookmakerA.key);
        const parsedDate = dayjs(bookmaker?.time, bookmaker.searchBy === "day" ? "DD/MM/YYYY" : "MM/YYYY");
        const initialDateFrom = parsedDate.isValid() ? parsedDate.startOf("day") : dayjs().subtract(5, "days").startOf("day");
				const initialDateTo = parsedDate.isValid() ? parsedDate.endOf("day") : dayjs().endOf("day");
        setDateFrom(initialDateFrom.format("YYYY-MM-DD HH:mm:ss"));
        setDateTo(initialDateTo.format('YYYY-MM-DD HH:mm:ss'));
        setSearchBy(bookmaker.searchBy)
        form.setFieldsValue({
          bookmarker: bookmakerA.key,
          dateFrom: initialDateFrom,
          dateTo: initialDateTo,
          searchBy: bookmaker.searchBy,
        });
        objState = {
          bookmarkerSelected: bookmakerA.key,
          gameCategory: "all",
          gameTypes: bookmaker.gameSearch,
          dateFrom: initialDateFrom.unix(),
          dateTo: initialDateTo.unix(),
          searchBy: bookmaker.searchBy,
        }
        if(bookmaker.game && bookmaker.game.length > 0) {
          objState.gameTypes = bookmaker.game.toString()
          form.setFieldsValue({ gameType: bookmaker.game });
          setGame(bookmaker.game)
        }
      } else if (user) {
        const bookmakerA = bookmarkers.find(item => item.label == user.bookmarkerName);
        setBookmarkerSelected(bookmakerA.key);
        const parsedDate = dayjs(user?.time, "DD/MM/YYYY");
        const initialDateFrom = parsedDate ? dayjs(parsedDate, 'YYYY-MM-DD').startOf("day") : dayjs().subtract(5, 'days').startOf("day");
        const initialDateTo = parsedDate ? dayjs(parsedDate, 'YYYY-MM-DD').endOf("day") : dayjs().endOf("day");
        setDateFrom(initialDateFrom.format('YYYY-MM-DD HH:mm:ss'));
        setDateTo(initialDateTo.format('YYYY-MM-DD HH:mm:ss'));
        setSearchBy("day")
        setGameCategory(user.gameCategorySearch)
        form.setFieldsValue({
          username: user.username,
          bookmarker: bookmakerA.key,
          dateFrom: initialDateFrom,
          dateTo: initialDateTo,
        });
        objState = {
          username: user.username, 
          bookmarkerSelected: bookmakerA.key,
          gameCategory: user.gameCategorySearch,
          gameTypes: user.gameSearch,
          dateFrom: initialDateFrom.unix(),
          dateTo: initialDateTo.unix(),
          searchBy: "day",
        }
        if(user.gameSelected && user.gameSelected.length > 0) {
          objState.gameTypes = user.gameSelected.toString()
          form.setFieldsValue({ gameType: user.gameSelected });
          setGame(user.gameSelected)
        }
      }
      onSearch(objState);
      navigate(location.pathname, { replace: true, state: null });
    }
  }

  const colums = [
    {
      title: "STT",
      align: "center",
      key: "indexCol",
      dataIndex: "indexCol",
      // render: (text, record, index) => (
      //   <span>{(page - 1) * 10 + (index + 1)}</span>
      // ),
      // render: (text, record, index) => <span>{(page - 1) * 10 + (index + 1)}</span>,

    },
    {
      title: "Tên HV mã hóa",
      dataIndex: "username",
      key: "username",
    },
    {
      title: TEXT_DEF.text0160,
      dataIndex: "time",
      key: "time",
    },
    {
      title: "Trò chơi",
      dataIndex: "game",
      key: "game",
    },
    // {
    //   title: "Tổng user",
    //   dataIndex: "totalUsers",
    //   key: "totalUsers",
    // },
    // {
    //   title: "User mới",
    //   dataIndex: "newUsers",
    //   key: "newUsers",
    // },
    {
      title: "Đơn cược",
      dataIndex: "count",
      key: "count",
    },
    {
      title: "Đơn cược thắng",
      dataIndex: "countOrderWin",
      key: "countOrderWin",
    },
    {
      title: "Tiền cược",
      dataIndex: "totalBet",
      key: "totalBet",
    },
    {
      title: "Tiền khách thắng",
      dataIndex: "paymentWin",
      key: "paymentWin",
    },
    // {
    //   title: "Tiền khuyễn mãi",
    //   dataIndex: "bonus",
    //   key: "bonus",
    // },
    {
      title: "Lợi nhuận nhà điều hành",
      dataIndex: "profit",
      key: "profit",
    },
    {
      title: "% thắng HV",
      dataIndex: "percentProfit",
      key: "percentProfit",
    },
    // {
    //   title: "Hành động",
    //   key: "setting",
    //   align: "center",
    //   render: (record) =>
    //     <div className="flex w-full items-center justify-center">
    //       <a href="#" title="Thống kê theo game" />
    //       <a href="#" title="Thống kê theo nền tảng" />
    //     </div>,
    // }
  ];

  useEffect(() => {
    forceUpdate({});
  }, []);

  useEffect(() => {
    form.setFieldValue("gameType", undefined)
    // setGame(undefined);
    if (gameCategory === "lottery") {
      getGameTypes(0);
    } else if (gameCategory === "casino") {
      getGameTypes(1);
    } else {
      getGameTypes(-1);
    }
  }, [gameCategory]);

  const getGameTypes = async (type = null) => {
    const result = await API.get(`${GAME}/all/${type}`);
    if (!result?.data?.data?.result || result?.data?.data?.result.length === 0) {
      setGameTypes([]);
      return;
    };

    let gameTypesXSN = (result?.data?.data?.result || []).map((item) => {
      return {
        key: item?.id,
        value: item?.category === "xoso" ? `${item?.parrentType}-${extractNumbers(item?.type)}` : item?.parrentType,
        label: getGame(item?.parrentType, extractNumbers(item?.type)),
      };
    });
    const gameTypesXSTT = await getGameTypesXSTT();
    let allGames = gameTypesXSN;
    if (type !== 1) {
      allGames = [...gameTypesXSN, ...gameTypesXSTT];
    }
    setGameTypes(allGames);
  }

  const getGameTypesXSTT = async () => {
    const result = await API.get(`${GAME}/xstt`);
    if (!result?.data?.data || result?.data?.data?.length === 0) {
      return [];
    }

    const games = result?.data?.data || [];
    const { gamesMT, gamesMB, gamesMN } = games.reduce((init, currentValue) => {
      if (currentValue.parrentType === 'xsmt') {
        init.gamesMT.push(currentValue);
      } else if (currentValue.parrentType === 'xsmn') {
        init.gamesMN.push(currentValue);
      } else {
        init.gamesMB.push(currentValue);
      }

      return init;
    }, { gamesMT: [], gamesMB: [], gamesMN: [] });
    const gamesFinal = gamesMB.concat(gamesMT).concat(gamesMN);

    return gamesFinal.map((item) => {
      return {
        key: item?.id,
        value: `${item.lotteryType}_${item.parrentType}_${item.type}`,
        label: item.textView.includes('XS') ? item.textView.replace('XS', 'Xổ Số') : item.textView,
      };
    });
  };

  const onSearch = async (objState = null) => {
    await getDataList(objState);
  };

  const getParams = (searchPage = null) => {

    if (!dateFrom || !dateTo || !gameCategory || !bookmarkerSelected) return;
    let params = "";
     
    if (bookmarkerSelected) {
      params += `bookmarkerId=${bookmarkerSelected}`;
    }
    if (searchBy) {
      params += `&searchBy=${searchBy}`;
    }
    if (dateFrom) {
      params += `&fromDate=${moment(dateFrom).unix()}`;
    }
    if (dateTo) {
      params += `&toDate=${moment(dateTo).unix()}`;
    }
    if (game && game?.length > 0) {
      params += `&gameType=${game}`;
    }
    if (gameCategory) {
      params += `&game=${gameCategory}`;
      if (!game || game?.length === 0) {
        const dataGame = gameTypes.map(obj => obj.value).join(',');
        params += `&gameType=${dataGame}`;
      }
    }
    const username = form.getFieldValue('username');
    if (username) {
      params += `&username=${username}`;
    }
    if(searchPage) {
      params += `&page=${searchPage}`;
    } else {
      params += `&page=${page}`;

    }

    return params;
  }

  const getParamsWithState = (object) => {
    if (!object?.dateFrom || !object?.dateTo || !object?.gameCategory || !object?.bookmarkerSelected) return;
    let params = "";
    if (object?.bookmarkerSelected) {
      params += `bookmarkerId=${object?.bookmarkerSelected}`;
    }
    if (object?.searchBy) {
      params += `&searchBy=${object?.searchBy}`;
    }
    if (object?.dateFrom) {
      params += `&fromDate=${object?.dateFrom}`;
    }
    if (object?.dateTo) {
      params += `&toDate=${object?.dateTo}`;
    }
    if (object?.game && object?.game?.length > 0) {
      params += `&gameType=${object?.game}`;
    }
    if (object?.gameCategory) {
      params += `&game=${object?.gameCategory}`;
      if (!object?.game || object?.game?.length === 0) {
        // const dataGame = object?.username ? object?.gameTypes : object?.gameTypes.map(obj => obj.value).join(',');
        const dataGame =  object?.gameTypes ;
        // console.log(dataGame, object?.gameTypes)
        params += `&gameType=${dataGame}`;
      }
    }
    if (object?.username) {
      params += `&username=${object?.username.trim()}`;
    }

    params += `&page=${page}`;

    return params;
  }

  const getDataList = async (objState) => {
    try {
      const searchPage = 1;
      const params = objState ? getParamsWithState(objState) : getParams(searchPage);
      setPage(searchPage)
			if (!params) return;

      setLoading(true);
      const result = await API.get(`${STATISTIC}/by-game?${params}`);
      const ordersInfo = result.data?.data?.ordersInfo || [];
      const total = result.data?.data?.ordersInfo? result.data?.data?.ordersInfo.length : 0;
      const finalResult = [];
      setTotalRecord(total);
      let allOrdersWin = 0;
      let allOrders = 0;
      let allBet = 0;
      let allAmountUserWin = 0;
      let allProfit = 0;
      let indexCol = 1;
      let allPercentProfit = 0;
      if (ordersInfo?.length > 0) {
        for (const item of ordersInfo) {
          const profit = Number(item.bookmarkerProfit);
          const percentProfit = Number((Number(item?.countOrderWin) / Number(item?.count) * 100) || 0).toFixed(2);
          const lotteryGame = item?.typeGame?.split('_')[0];
          let dataTypegame = item?.typeGame;
          if (lotteryGame !== 'xstt') {
            if (dataTypegame?.indexOf("-") > -1) {
              const parrentType = dataTypegame.substring(0, dataTypegame?.indexOf("-"));
              const seconds = dataTypegame.substring(dataTypegame?.indexOf("-") + 1, dataTypegame.length - 1);
              dataTypegame = getGame(parrentType, seconds);
            }
          } else {
            const province = item?.typeGame?.split('_')[2];
            dataTypegame = getGameNameXSTT(province);
          }
  
          finalResult.push({
            indexCol: indexCol++,
            profit: dollarUS.format(Number(profit)),
            percentProfit: (Number(percentProfit) <= 100 && Number(percentProfit) > 0) ? percentProfit : 0,
            // percentProfit: 
            //   (Number(percentProfit) <= 100 && Number(percentProfit) > 0) || 
            //   (Number(percentProfit) >= -100 && Number(percentProfit) < 0) ? 
            //   percentProfit : Number(percentProfit) > 100 ? 100 : -100,
            bookmarkerProfit: item.bookmarkerProfit,
            count: item.count,
            countOrderWin: item.countOrderWin,
            newUsers: item.newUsers,
            paymentWin: dollarUS.format(Number(item.paymentWin)),
            time: item.time,
            totalBet: dollarUS.format(Number(item.totalBet)),
            totalUsers: item.totalUsers,
            bookmarkerName: item.bookmarkerName,
            game: dataTypegame,
            username: item?.username
          });
  
          allOrders += Number(item.count) || 0;
          allOrdersWin += Number(item.countOrderWin) || 0;
          allBet += Number(item.totalBet) || 0;
          allAmountUserWin += Number(item.paymentWin) || 0;
          allProfit += Number(profit);
          allPercentProfit = ((allProfit / allBet) * 100).toFixed(2);
        }
      }

      setFinalInfo({
        allOrders,
        allBet,
        allAmountUserWin,
        allProfit,
        allOrdersWin,
        allPercentProfit,
      });
      
      setDataList(finalResult);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }

  }

  // useEffect(() => {
  //   // getDataList();

  //   let allOrdersWin = 0;
  //   let allOrders = 0;
  //   let allBet = 0;
  //   let allAmountUserWin = 0;
  //   let allProfit = 0;
  //   let allPercentProfit = 0;
  //   if(currentData.length > 0) {
  //     currentData.map((item) => {
  //       // console.log(Number(item.totalBet), item.totalBet)
  //         allOrders += Number(item.count.replace(/,/g, "")) || 0;
  //         allOrdersWin += Number(item.countOrderWin.replace(/,/g, "")) || 0;
  //         allBet += Number(item.totalBet.replace(/,/g, "")) || 0;
  //         allAmountUserWin += Number(item.paymentWin.replace(/,/g, "")) || 0;
  //         allProfit += Number(item.profit.replace(/,/g, "")) || 0;
  //         allPercentProfit = ((allProfit /allBet) * 100).toFixed(2) || 0;
  //     })
  //   }
    

  //   setFinalInfoPP({
  //     allOrders,
  //     allBet,
  //     allAmountUserWin,
  //     allProfit,
  //     allOrdersWin,
  //     allPercentProfit,
  //   });

  // }, [page, dataList]);

  useEffect(() => {
    getBookmarkers();
  }, []);

  const getBookmarkers = async () => {
    const result = await API.get(`${BOOKMAKER}/all`);
    const bookmarkers = result.data?.data?.result[0] || [];
    if (!bookmarkers || bookmarkers.length === 0) return;

    const tempBookmarkers = bookmarkers?.map((item) => {
      return {
        key: item?.id,
        value: item?.id,
        label: item?.name,
      };
    });
    setBookmarkers(tempBookmarkers);
    setBookmarkerSelected(tempBookmarkers?.[0]?.key);
    initFormValues({
      bookmarker: tempBookmarkers?.[0]?.label,
    });
  };

  const initFormValues = (initData) => {
    if (!initData) return;
    
    setGameCategory("all");
    setSearchBy("day");
    for (const key in initData) {
      form.setFieldsValue({
        [key]: initData[key],
        searchBy: "day",
        gameCategory: "all",
      });

    }
  }

  const dollarUS = Intl.NumberFormat("en-US");

  const onChangeDate = (e, type) => {
    if (e) {
      if (type === "from") setDateFrom(moment(e.$d).format('yyyy-MM-DD HH:mm:ss'));
      else setDateTo(moment(e.$d).format("yyyy-MM-DD HH:mm:ss"));
    } else {
      if (type === "from") setDateFrom(null);
      else setDateTo(null);
    }

  };


  const filterOptionBm = (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  const onChangeTab = (e) => {
    setIndexTab(e.target.value);
    if (e.target.value == 2) {
      // getDataLotteryByGame(0)
    }
  };


  const startIndex = (page - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const currentData = dataList.slice(startIndex, endIndex);

  const onExportExl = async () => {
    try {
      const params = getParams();
      if (!params) return;

      setLoading(true);
      const result = await API.get(`${STATISTIC}/by-game?${params}`);
      const ordersInfo = result.data?.data?.ordersInfo || [];
      const total = result.data?.data?.total || 0;
      const finalResult = [];
      setTotalRecord(total);

      for (const item of ordersInfo) {
        const profit = Number(item.bookmarkerProfit);
        const percentProfit = Number((Number(item?.countOrderWin) / Number(item?.count) * 100) || 0).toFixed(2);
        const lotteryGame = item?.typeGame?.split('_')[0];
        let dataTypegame = item?.typeGame;
        if (lotteryGame !== 'xstt') {
          if (dataTypegame?.indexOf("-") > -1) {
            const parrentType = dataTypegame.substring(0, dataTypegame?.indexOf("-"));
            const seconds = dataTypegame.substring(dataTypegame?.indexOf("-") + 1, dataTypegame.length - 1);
            dataTypegame = getGame(parrentType, seconds);
          }
        } else {
          const province = item?.typeGame?.split('_')[2];
          dataTypegame = getGameNameXSTT(province);
        }

        finalResult.push({
          username: item?.username,
          time: item.time,
          game: dataTypegame,
          count: item.count,
          countOrderWin: item.countOrderWin,
          totalBet: Number(item.totalBet),
          paymentWin: Number(item.paymentWin),
          profit: Number(profit),
          percentProfit: (Number(percentProfit) <= 100 && Number(percentProfit) > 0) ? percentProfit : 0,
        });
      }

      exportToExcel(finalResult)
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  }

  const exportToExcel = (data) => {
    const name = "Thống kê theo trò chơi"

    const formattedData = data.map(item => ({
      'Tên HV mã hóa': item.username,
      'Thời gian': item.time,
      'Trò chơi': item.game,
      'Đơn cược': item.count,
      'Đơn cược thắng': item.countOrderWin,
      'Tiền cược': item.totalBet,
      'Tiền khách thắng': item.paymentWin,
      'Lợi nhuận nhà điều hành': item.profit,
      '% thắng HV': item.percentProfit,
    }));

    const ws = XLSX.utils.json_to_sheet(formattedData);
    const columnFormats = [
      { header: 'Tên HV mã hóa', key: 'Tên HV mã hóa' },
      { header: 'Thời gian', key: 'Thời gian' },
      { header: 'Trò chơi', key: 'Trò chơi' },
      { header: 'Đơn cược', key: 'Đơn cược', type: 'n' },
      { header: 'Đơn cược thắng', key: 'Đơn cược thắng', type: 'n' },
      { header: 'Tiền cược', key: 'Tiền cược', type: 'n' },
      { header: 'Tiền khách thắng', key: 'Tiền khách thắng', type: 'n' },
      { header: 'Lợi nhuận nhà điều hành', key: 'Lợi nhuận nhà điều hành', type: 'n' },
      { header: '% thắng HV', key: '% thắng HV', type: 'n' },
    ];

    formattedData.forEach((row, rowIndex) => {
      columnFormats.forEach((col, colIndex) => {
        const cellRef = XLSX.utils.encode_cell({ c: colIndex, r: rowIndex + 1 });
        if (col.type === 'n') {
          ws[cellRef].t = 'n';
        }
      });
    });

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    saveAs(new Blob([wbout], { type: 'application/octet-stream' }), `${name}.xlsx`);
  };

  const handleBookmarkerSelected = (val)  => {
    setBookmarkerSelected(val)
  }

  
  const disabledDateFrom = (current) => {
    const isAfterEndOfDay = (current) => current && current > dayjs().endOf("day");
    const isBeforeStartOfDateTo = (current, months) => current < dayjs(dateTo).subtract(months, "months").startOf("day");
    const isAfterEndOfDateTo = (current) => current > dayjs(dateTo).endOf("day");
    if (!current) return false;

    const monthsToSubtract = searchBy === "day" ? 3 : 2;

    if (!dateTo) {
      return isAfterEndOfDay(current);
    }

    return isAfterEndOfDay(current) || isBeforeStartOfDateTo(current, monthsToSubtract) || isAfterEndOfDateTo(current);
  };

  const disabledDateTo = (current) => {
    const isAfterEndOfDay = (current) => current && current > dayjs().endOf("day");
    const isBeforeStartOfDateFrom = (current) => current < dayjs(dateFrom).startOf("day");
    const isAfterEndOfDateFrom = (current, months) => current > dayjs(dateFrom).add(months, "months").endOf("day");
    if (!current) return false;

    const monthsToAdd = searchBy === "day" ? 3 : 2;

    if (!dateFrom) {
      return isAfterEndOfDay(current);
    }

    return isAfterEndOfDay(current) || isBeforeStartOfDateFrom(current) || isAfterEndOfDateFrom(current, monthsToAdd);
  };
  const renderSearch = () => {
    return (
      <>
        <div className="col-12">
          <Form
            form={form}
            layout="inline"
            autoComplete="off"
            onFinish={() => onSearch()}
          >
            <>
              <div
                className="col-12"
                style={{ marginBottom: 15, display: "flex" }}
              >
                {
                  hasRight([RIGHTS.Super]) ? (
                    <div className="col-4">
                      <Form.Item name="bookmarker" label={TEXT_DEF.text0011} rules={[{ required: true, message: 'Vui lòng nhập thông tin!' }]}>
                        <Select
                          showSearch
                          placeholder="Chọn nhà cái"
                          optionFilterProp="children"
                          onChange={(val) => setBookmarkerSelected(val)}
                          filterOption={filterOptionBm}
                          options={bookmarkers}
                          allowClear
                        />
                      </Form.Item>
                    </div>
                  ) : null
                }
                <div className="col-4">
                  <Form.Item name="gameCategory" label={`Thể loại game`} rules={[{ required: true, message: 'Vui lòng nhập thông tin!' }]}>
                    <Select
                      showSearch
                      optionFilterProp="children"
                      onChange={(val) => setGameCategory(val)}
                      filterOption={filterOptionBm}
                      options={opGameCategory}
                      allowClear
                    />
                  </Form.Item>
                </div>
                <div className="col-4">
                  <Form.Item name="username" label={`Tên HV mã hóa`}>
                    <Input style={{ width: '100%' }}/>
                  </Form.Item>
                </div>
              </div>
              
              {gameCategory &&
                <div
                  className="col-12"
                  style={{ marginBottom: 15, display: "flex" }}
                >
                  <div className="col-8 flex" style={{ alignItems: 'center' }}>
                    <div className="col-1">Game: </div>
                    <Form.Item name="gameType" className="col-11">
                      <Select
                        mode="multiple"
                        showSearch
                        optionFilterProp="children"
                        onChange={(val) => setGame(val)}
                        filterOption={filterOptionBm}
                        options={gameTypes}
                        allowClear
                        // value={game}
                      >
                        
                      </Select>
                    </Form.Item>
                  </div>
                </div>
              }
              <div
                className="col-12"
                style={{ marginBottom: 15, display: "flex" }}
              >
                <div className="col-4">
                  <Form.Item name="searchBy" label={TEXT_DEF.text0096} rules={[{ required: true, message: 'Vui lòng nhập thông tin!' }]}>
                    <Select onChange={(val) => setSearchBy(val)} allowClear>
                      <Select.Option value="month">{TEXT_DEF.text0090}</Select.Option>
                      <Select.Option value="day">{TEXT_DEF.text0089}</Select.Option>
                      {/* <Select.Option value="hour">Theo giờ</Select.Option> */}
                    </Select>
                  </Form.Item>
                </div>
                
                {searchBy === "day" || searchBy === "month" ? (
                  <>
                    <div className="col-4">
                      <Form.Item
                        name="dateFrom"
                        label={TEXT_DEF.text0024}
                        rules={[
                          { required: true, message: "Vui lòng nhập thông tin!" },
                          ...(searchBy === "day"
                            ? [
                                {
                                  validator: () => {
                                    return validateDateRange(dateFrom, dateTo);
                                  },
                                },
                              ]
                            : []),
                        ]}
                      >
                        {searchBy != "day" ? (
                          <DatePicker
                            picker={searchBy}
                            placeholder=""
                            onChange={(e) => onChangeDate(e, "from")}
                            value={dateFrom}
                            format={"MM-YYYY"}
                            style={{ width: "100%" }}
                            disabledTime={(d) => !d || d.isSameOrAfter(dateTo ? moment(dateTo).add(1, "d") : moment())}
                            disabledDate={disabledDateFrom}
                          />
                        ) : (
                          <DatePicker
                            placeholder=""
                            onChange={(e) => onChangeDate(e, "from")}
                            value={dateFrom}
                            format={"DD-MM-YYYY HH:mm:ss"}
                            showTime={{ format: "HH:mm:ss" }}
                            style={{ width: "100%" }}
                            disabledTime={disabledTimeFrom(dateTo)}
                            disabledDate={disabledDateFrom}
                            // inputReadOnly={true}
                          />
                        )}
                      </Form.Item>
                    </div>
                    <div className="col-4">
                      <Form.Item name="dateTo" label={TEXT_DEF.text0025} rules={[{ required: true, message: "Vui lòng nhập thông tin!" }]}>
                        {searchBy != "day" ? (
                          <DatePicker
                            picker={searchBy}
                            placeholder=""
                            selected={dateTo}
                            onChange={(e) => onChangeDate(e, "to")}
                            value={dateTo}
                            format={"MM-YYYY"}
                            style={{ width: "100%" }}
                            disabledTime={(d) => !d || d.isSameOrBefore(dateFrom ? dateFrom : null) || d.isSameOrAfter(moment())}
                            disabledDate={disabledDateTo}
                          />
                        ) : (
                          <DatePicker
                            placeholder=""
                            selected={dateTo}
                            onChange={(e) => onChangeDate(e, "to")}
                            value={dateTo}
                            format={"DD-MM-YYYY HH:mm:ss"}
                            showTime={{ defaultValue: moment('23:59:59', 'HH:mm:ss') }}
                            style={{ width: "100%" }}
                            disabledTime={disabledTimeTo(dateFrom)}
                            disabledDate={disabledDateTo}
                            // inputReadOnly={true}
                          />
                        )}
                      </Form.Item>
                    </div>
                  </>
                ) : null}
              </div>
            </>
            <div className="payment-search">
              <Button htmlType="submit" type="primary">
                {TEXT_DEF.text0026}
              </Button>
              {
                hasRight([RIGHTS.ExportExcelReportOrdersByGame]) ? (
                  <Button onClick={onExportExl} style={{ marginLeft: 20 }} htmlType="submit" type="primary">
                    {TEXT_DEF.text0027}
                  </Button>
                ) : null
              }
            </div>
          </Form>
        </div>
      </>
    );
  };

  const onChangePage = (page, size) => {
    setPage(page);
    setPageSize(size)
  };

  const showTotal = (total) => `Tổng cộng ${total} dữ liệu`;

  const renderTableData = () => {
    return (
      <Spin spinning={loading} delay={500} size="large">
        <div
          style={{
            display: "flex",
            marginTop: 5,
            marginRight: 20,
          }}
        >
          <div className="text-lg font-bold mr-5">
            <label>Tổng đơn cược: </label> <span>{dollarUS.format(finalInfo.allOrders)}</span>
          </div>
          <div className="text-lg font-bold mr-5">
            <label>Tổng đơn cược thắng:</label> <span>{dollarUS.format(finalInfo.allOrdersWin)}</span>
          </div>
          <div className="text-lg font-bold mr-5">
            <label>Tổng tiền cược: </label>
            <span>{dollarUS.format(finalInfo.allBet)}</span>
          </div>
          <div className="text-lg font-bold mr-5">
            <label>Tổng tiền khách thắng: </label>
            <span>{dollarUS.format(finalInfo.allAmountUserWin)}</span>
          </div>
          <div className="text-lg font-bold mr-5">
            <label>Tổng lợi nhuận:</label> <span>{dollarUS.format(finalInfo.allProfit)}</span>
          </div>
          <div className="text-lg font-bold mr-5">
            <label>Phần trăm lợi nhuận:</label> <span>{finalInfo.allPercentProfit}</span>
          </div>
        </div>
        {/* <hr></hr>
        Tổng từng page
        <div
          style={{
            display: "flex",
            marginTop: 5,
            marginRight: 20,
          }}
        >
          <div className="text-lg font-bold mr-5">
            <label>Tổng đơn cược : </label> <span>{dollarUS.format(finalInfoPP.allOrders)}</span>
          </div>
          <div className="text-lg font-bold mr-5">
            <label>Tổng đơn cược thắng :</label> <span>{dollarUS.format(finalInfoPP.allOrdersWin)}</span>
          </div>
          <div className="text-lg font-bold mr-5">
            <label>Tổng tiền cược: </label>
            <span>{dollarUS.format(finalInfoPP.allBet)}</span>
          </div>
          <div className="text-lg font-bold mr-5">
            <label>Tổng tiền khách thắng: </label>
            <span>{dollarUS.format(finalInfoPP.allAmountUserWin)}</span>
          </div>
          <div className="text-lg font-bold mr-5">
            <label>Tổng lợi nhuận :</label> <span>{dollarUS.format(finalInfoPP.allProfit)}</span>
          </div>
          <div className="text-lg font-bold mr-5">
            <label>Phần trăm lợi nhuận:</label> <span>{finalInfoPP.allPercentProfit}</span>
          </div>
        </div> */}
        <div style={{ display: "flex", width: "100%", marginTop: 20 }}>
          <Table
            key="userList"
            locale={locale}
            columns={colums}
            dataSource={currentData}
            pagination={false}
            bordered
            style={{ width: "100%" }}
            scroll={{
              x: 1200,
            }}
          />
        </div>
        <div
          style={{
            display: "flex",
            width: "100%",
            marginTop: 15,
            justifyContent: "flex-end",
          }}
        >
          <Pagination
            current={page}
            total={totalRecord}
            onChange={onChangePage}
            showSizeChanger={true}
            showTotal={showTotal}
            pageSizeOptions={['10', '50', '100', '200', '500', '1000']}
          />
        </div>
      </Spin>
    );
  }

  return (
    <div className="col-12">
      {renderSearch()}
      {renderTableData()}
      {isShowModalChart && (
        <ModalChart
          isShow={isShowModalChart}
          dataChart={dataChartModal}
          title={titleChartModal}
          close={() => setIsShowModalChart(false)}
        />
      )}
    </div>
  );
};

export default ReportByGame;
